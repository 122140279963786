import { FormattedMessage } from 'react-intl'
import { Menu } from 'antd'
import { Link, useLocation } from 'react-router-dom'

const CustomMenu = props => {
  const location = useLocation()
  return (
    <Menu
      mode={'horizontal'}
      selectedKeys={[location.pathname]}
      style={{ lineHeight: '57px' }}>
      <Menu.Item key={'/'}>
        <Link to={'/'}>
          <FormattedMessage id={'app.menuItem.queues'} />
        </Link>
      </Menu.Item>
      <Menu.Item key={'/tills'}>
        <Link to={'/tills'}>
          <FormattedMessage id={'app.menuItem.tills'} />
        </Link>
      </Menu.Item>
      <Menu.Item key={'/displays'}>
        <Link to={'/displays'}>
          <FormattedMessage id={'app.menuItem.displays'} />
        </Link>
      </Menu.Item>
    </Menu>
  )
}
export default CustomMenu
