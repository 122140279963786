import styled from 'styled-components'
import { Button, Layout } from 'antd'

import { colors } from './constants'

export const WhiteLayout = styled(Layout)`
  background: ${colors.white};
`
export const PaddedContent = styled(Layout.Content)`
  padding: 16px;
  min-height: calc(100vh - 112px);
`
export const PageTitle = styled.h1`
  color: ${colors.main};
  text-align: center;
  text-transform: uppercase;
`
export const UppercaseButton = styled(Button)`
  text-transform: uppercase;
`
