import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { Link } from 'react-router-dom'

import Menu from '../Menu'
import User from '../User'
import { HeaderBar, StyledHeader } from './styled'
import logo from '../../assets/img/logo.png'

const Header = props => {
  const { user } = props
  return (
    <>
      <HeaderBar />
      <StyledHeader>
        <Link to={'/'}>
          <img src={logo} alt={'logo'} height={32} />
        </Link>
        <Menu />
        {!isEmpty(user) ? <User user={user} /> : null}
      </StyledHeader>
    </>
  )
}
Header.propTypes = {
  user: PropTypes.object
}
export default Header