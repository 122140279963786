import axios from 'axios'
import config from './config'

export const BE_API = axios.create({
  baseURL: config.API_URL,
  headers: {
    'API-Key': 'C7F4B716-464D-4037-8B81-F8C5508B427C'
  }
})

const apis = {
  user: {
    authenticate: token =>
      BE_API.get('/user', { headers: { Authorization: `Bearer ${token}` } })
  },
  queue: {
    list: () => BE_API.get('/queues'),
    upsert: body => BE_API.post('/queue', body),
    delete: (queueId, storeId) => BE_API.delete(`/queues/${queueId}/${storeId}`)
  },
  till: {
    list: () => BE_API.get('/tills'),
    upsert: body => BE_API.post('/till', body),
    delete: tillId => BE_API.delete(`/tills/${tillId}`)
  },
  display: {
    list: () => BE_API.get('/displays'),
    upsert: body => BE_API.post('/display', body),
    delete: displayId => BE_API.delete(`/displays/${displayId}`)
  }
}
export default apis
