import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { injectIntl } from 'react-intl'
import { Form as AntForm, Input, Modal, Select, Switch } from 'antd'

import Loader from '../Loader'

import { TYPE_DISPLAYS, TYPE_QUEUES, TYPE_TILLS } from 'src/common/constants'

const getModalFormConfig = (intl, type, row, queues) => {
  const modalProps = {
    title: intl.formatMessage({
      id: isEmpty(row) ? `${type}.modal.title.new` : `${type}.modal.title.edit`
    }),
    okText: intl.formatMessage({
      id: isEmpty(row)
        ? `${type}.modal.okText.new`
        : `${type}.modal.okText.edit`
    }),
    cancelText: intl.formatMessage({ id: `${type}.modal.cancelText` })
  }
  switch (type) {
    case TYPE_QUEUES:
      return {
        key: `${row.storeId}_${row.queueId}`,
        ...modalProps,
        formFields: (
          <>
            <AntForm.Item
              name={'storeId'}
              hidden={!isEmpty(row)}
              label={intl.formatMessage({ id: 'queues.form.label.storeId' })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'queues.form.validation.storeId'
                  })
                }
              ]}>
              <Input />
            </AntForm.Item>
            <AntForm.Item name={'queueId'} hidden>
              <Input />
            </AntForm.Item>
            <AntForm.Item
              name={'queueName'}
              label={intl.formatMessage({ id: 'queues.form.label.name' })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'queues.form.validation.name'
                  })
                }
              ]}>
              <Input />
            </AntForm.Item>
            <AntForm.Item
              name={'enabled'}
              label={intl.formatMessage({ id: 'queues.form.label.enabled' })}
              valuePropName={'checked'}
              initialValue={true}>
              <Switch />
            </AntForm.Item>
          </>
        )
      }
    case TYPE_TILLS:
      return {
        key: row.tillId,
        ...modalProps,
        formFields: (
          <>
            <AntForm.Item
              name={'till'}
              label={intl.formatMessage({ id: 'tills.form.label.till' })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'tills.form.validation.till'
                  })
                }
              ]}>
              <Input />
            </AntForm.Item>
            <AntForm.Item
              name={'tillId'}
              hidden={!isEmpty(row)}
              label={intl.formatMessage({ id: 'tills.form.label.tillId' })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'tills.form.validation.tillId'
                  })
                }
              ]}>
              <Input />
            </AntForm.Item>
            <AntForm.Item
              name={'queue'}
              label={intl.formatMessage({ id: 'tills.form.label.queue' })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'tills.form.validation.queue'
                  })
                }
              ]}
              initialValue={!isEmpty(row) && `${row.queueId}_${row.storeId}`}>
              <Select>
                {queues.map(q => (
                  <Select.Option key={`${q.queueId}_${q.storeId}`}>
                    {q.queueName}
                  </Select.Option>
                ))}
              </Select>
            </AntForm.Item>
          </>
        )
      }
    case TYPE_DISPLAYS:
      return {
        key: row.displayId,
        ...modalProps,
        formFields: (
          <>
            <AntForm.Item name={'displayId'} hidden>
              <Input />
            </AntForm.Item>
            <AntForm.Item
              name={'queue'}
              label={intl.formatMessage({ id: 'displays.form.label.queue' })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'displays.form.validation.queue'
                  })
                }
              ]}
              initialValue={!isEmpty(row) && `${row.queueId}_${row.storeId}`}>
              <Select>
                {queues.map(q => (
                  <Select.Option key={`${q.queueId}_${q.storeId}`}>
                    {q.queueName}
                  </Select.Option>
                ))}
              </Select>
            </AntForm.Item>
            <AntForm.Item
              name={'timer'}
              label={intl.formatMessage({ id: 'displays.form.label.timer' })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'displays.form.validation.timer'
                  })
                }
              ]}>
              <Input />
            </AntForm.Item>
          </>
        )
      }
    default:
      return {}
  }
}

const Form = ({ row, formFields, setFormRef }) => {
  const [form] = AntForm.useForm()
  setFormRef(form)
  return (
    <AntForm form={form} initialValues={row}>
      {formFields}
    </AntForm>
  )
}

const ModalForm = props => {
  const {
    intl,
    isLoading,
    isVisible,
    type,
    row,
    queues,
    closeModal,
    submitForm
  } = props
  const [formRef, setFormRef] = useState(null)

  const modalFormConfig = getModalFormConfig(intl, type, row, queues)

  const onClose = useCallback(() => {
    if (!formRef) return
    formRef.resetFields()
    closeModal()
  }, [formRef, closeModal])

  const onOk = useCallback(() => {
    if (!formRef) return
    formRef.validateFields().then(values => submitForm(values))
  }, [formRef, submitForm])

  return (
    <Modal
      visible={isVisible}
      title={modalFormConfig.title}
      okText={modalFormConfig.okText}
      cancelText={modalFormConfig.cancelText}
      onCancel={onClose}
      onOk={onOk}>
      {isLoading ? (
        <Loader height={'100px'} />
      ) : (
        <Form
          row={row}
          formFields={modalFormConfig.formFields}
          key={modalFormConfig.key}
          setFormRef={setFormRef}
        />
      )}
    </Modal>
  )
}
ModalForm.propTypes = {
  closeModal: PropTypes.func,
  isLoading: PropTypes.bool,
  isVisible: PropTypes.bool,
  row: PropTypes.object,
  submitForm: PropTypes.func,
  type: PropTypes.string.isRequired
}
export default injectIntl(ModalForm)
