import { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import {
  getQueues,
  getDisplays,
  upsertDisplay,
  deleteDisplay
} from '../../redux-store/actions/app-actions'

import Table from 'src/components/Table'
import ModalForm from 'src/components/ModalForm'
import { PageTitle } from 'src/common/styled'

import { TYPE_DISPLAYS } from 'src/common/constants'

class Displays extends Component {
  static propTypes = {
    deleteDisplay: PropTypes.func,
    displays: PropTypes.arrayOf(PropTypes.object),
    getDisplays: PropTypes.func,
    getQueues: PropTypes.func,
    queues: PropTypes.arrayOf(PropTypes.object),
    upsertDisplay: PropTypes.func
  }
  state = {
    isLoading: false,
    isModalVisible: false,
    selectedRow: {}
  }

  componentDidMount() {
    this.getDisplays()
  }

  getQueues = async () => {
    const { getQueues } = this.props
    this.setState({ isLoading: true })
    await getQueues()
    this.setState({ isLoading: false })
  }

  getDisplays = async () => {
    const { getDisplays } = this.props
    this.setState({ isLoading: true })
    await getDisplays()
    this.setState({ isLoading: false })
  }

  upsertDisplay = async body => {
    const { upsertDisplay } = this.props
    this.setState({ isLoading: true })
    await upsertDisplay(body)
    this.getDisplays()
    this.closeEditModal()
  }

  deleteDisplay = async displayId => {
    const { deleteDisplay } = this.props
    this.setState({ isLoading: true })
    await deleteDisplay(displayId)
    this.getDisplays()
  }

  openEditModal = row => {
    this.getQueues()
    this.setState({ isModalVisible: true, selectedRow: row })
  }
  closeEditModal = () => {
    this.setState({ isModalVisible: false, selectedRow: {} })
  }

  render() {
    const { isLoading, isModalVisible, selectedRow } = this.state
    const { queues, displays } = this.props
    return (
      <>
        <PageTitle>
          <FormattedMessage id={'displays.title'} />
        </PageTitle>
        <Table
          isLoading={isLoading}
          data={displays}
          type={TYPE_DISPLAYS}
          openEditModal={this.openEditModal}
          deleteRow={this.deleteDisplay}
        />
        <ModalForm
          isLoading={isLoading}
          isVisible={isModalVisible}
          type={TYPE_DISPLAYS}
          row={selectedRow}
          queues={queues}
          closeModal={this.closeEditModal}
          submitForm={this.upsertDisplay}
        />
      </>
    )
  }
}
const mapStateToProps = state => ({
  queues: state.app.queues,
  displays: state.app.displays
})
const mapDispatchToProps = {
  getQueues,
  getDisplays,
  upsertDisplay,
  deleteDisplay
}
export default connect(mapStateToProps, mapDispatchToProps)(Displays)
