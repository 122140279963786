// USER
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN'

// QUEUES
export const GET_QUEUES = 'GET_QUEUES'
export const UPSERT_QUEUE = 'UPSERT_QUEUE'
export const DELETE_QUEUE = 'DELETE_QUEUE'

// TILLS
export const GET_TILLS = 'GET_TILLS'
export const UPSERT_TILL = 'UPSERT_TILL'
export const DELETE_TILL = 'DELETE_TILL'

// DISPLAYS
export const GET_DISPLAYS = 'GET_DISPLAYS'
export const UPSERT_DISPLAY = 'UPSERT_DISPLAY'
export const DELETE_DISPLAY = 'DELETE_DISPLAY'
