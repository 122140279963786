import { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import {
  getQueues,
  upsertQueue,
  deleteQueue
} from '../../redux-store/actions/app-actions'

import Table from 'src/components/Table'
import ModalForm from 'src/components/ModalForm'
import { PageTitle } from 'src/common/styled'

import { TYPE_QUEUES } from 'src/common/constants'

class Queues extends Component {
  static propTypes = {
    deleteQueue: PropTypes.func,
    getQueues: PropTypes.func,
    queues: PropTypes.arrayOf(PropTypes.object),
    upsertQueue: PropTypes.func
  }
  state = {
    isLoading: false,
    isModalVisible: false,
    selectedRow: {}
  }

  componentDidMount() {
    this.getQueues()
  }

  getQueues = async () => {
    const { getQueues } = this.props
    this.setState({ isLoading: true })
    await getQueues()
    this.setState({ isLoading: false })
  }

  upsertQueue = async body => {
    const { upsertQueue } = this.props
    this.setState({ isLoading: true })
    await upsertQueue(body)
    this.getQueues()
    this.closeEditModal()
  }

  deleteQueue = async (queueId, storeId) => {
    const { deleteQueue } = this.props
    this.setState({ isLoading: true })
    await deleteQueue(queueId, storeId)
    this.getQueues()
  }

  openEditModal = row => {
    this.setState({ isModalVisible: true, selectedRow: row })
  }
  closeEditModal = () => {
    this.setState({ isModalVisible: false, selectedRow: {} })
  }

  render() {
    const { isLoading, isModalVisible, selectedRow } = this.state
    const { queues } = this.props
    return (
      <>
        <PageTitle>
          <FormattedMessage id={'queues.title'} />
        </PageTitle>
        <Table
          isLoading={isLoading}
          data={queues}
          type={TYPE_QUEUES}
          openEditModal={this.openEditModal}
          deleteRow={this.deleteQueue}
        />
        <ModalForm
          isLoading={isLoading}
          isVisible={isModalVisible}
          type={TYPE_QUEUES}
          row={selectedRow}
          closeModal={this.closeEditModal}
          submitForm={this.upsertQueue}
        />
      </>
    )
  }
}
const mapStateToProps = state => ({
  queues: state.app.queues
})
const mapDispatchToProps = {
  getQueues,
  upsertQueue,
  deleteQueue
}
export default connect(mapStateToProps, mapDispatchToProps)(Queues)
