import { Component } from 'react'
// import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import Auth from '../Auth'
import Queues from '../Queues'
import Tills from '../Tills'
import Displays from '../Displays'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import FeedbackButton from '../../components/FeedbackButton'
import { WhiteLayout, PaddedContent } from '../../common/styled'

class App extends Component {
  static propTypes = {}

  componentDidMount() {
    const { user } = this.props

    this.tangram = new window.Tangram({
      productId: 'f2bc98a5-7fd3-4aa7-8c3c-108f6ea77991', // PUT YOUR PRODUCT ID HERE.
      userLdap: user.LDAP,
      buttonOptions: {
        // This options is needed so you don't have two buttons.
        noShowOnStartup: true
      }
    })
  }

  render() {
    const { user } = this.props
    return (
      <WhiteLayout>
        <Header user={user} />
        <Auth>
          <PaddedContent>
            <Switch>
              <Route component={Queues} exact path={'/'} />
              <Route component={Tills} exact path={'/tills'} />
              <Route component={Displays} exact path={'/displays'} />
            </Switch>
          </PaddedContent>
        </Auth>
        <Footer />
        <FeedbackButton onClick={() => this.tangram.open()} />
      </WhiteLayout>
    )
  }
}
const mapStateToProps = state => ({
  user: state.user.current
})
export default connect(mapStateToProps)(App)
