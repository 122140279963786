import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { is } from 'ramda'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Button, Input, Popconfirm, Space, Table as AntTable } from 'antd'
import {
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined
} from '@ant-design/icons'

import Loader from '../Loader'
import { UppercaseButton } from 'src/common/styled'
import {
  colors,
  TYPE_DISPLAYS,
  TYPE_QUEUES,
  TYPE_TILLS
} from 'src/common/constants'

const sorter = (a, b, dataIndex) => {
  if (is(Number, a[dataIndex]) || is(Boolean, a[dataIndex]))
    return a[dataIndex] - b[dataIndex]
  if (is(String, a[dataIndex])) return a[dataIndex].localeCompare(b[dataIndex])
  return 1
}

const getSearchFilterProps = (intl, type, dataIndex) => {
  return {
    filterDropdown: props => {
      const { selectedKeys, setSelectedKeys, confirm, clearFilters } = props
      return (
        <Space style={{ padding: 8 }}>
          <Input
            id={'search-input'}
            placeholder={intl.formatMessage(
              {
                id: 'table.placeholder.search'
              },
              {
                dataIndex: intl.formatMessage({
                  id: `${type}.table.column.${dataIndex}`
                })
              }
            )}
            value={selectedKeys[0]}
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
          />
          <Button type={'primary'} onClick={confirm}>
            <FormattedMessage id={'table.button.confirm'} />
          </Button>
          <Button onClick={clearFilters}>
            <FormattedMessage id={'table.button.clear'} />
          </Button>
        </Space>
      )
    },
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? colors.main : undefined }} />
    ),
    onFilter: (value, row) =>
      row[dataIndex]
        ? row[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : ''
  }
}

const getTableConfig = (intl, type, openEditModal, deleteRow) => {
  const actionsRow = deleteRowKeys => ({
    render: row => (
      <>
        <Button
          icon={<EditOutlined />}
          type={'primary'}
          ghost
          onClick={() => openEditModal(row)}
        />{' '}
        <Popconfirm
          title={intl.formatMessage({ id: `delete.alert.text.${type}` })}
          okText={intl.formatMessage({ id: 'delete.alert.okText' })}
          cancelText={intl.formatMessage({
            id: 'delete.alert.cancelText'
          })}
          onConfirm={() => deleteRow(...deleteRowKeys.map(key => row[key]))}>
          <Button icon={<DeleteOutlined />} type={'danger'} ghost />
        </Popconfirm>
      </>
    )
  })
  switch (type) {
    case TYPE_QUEUES:
      return {
        title: 'queues.button.new',
        rowKey: row => `${row.storeId}_${row.queueId}`,
        columns: [
          {
            key: 'queueId',
            title: intl.formatMessage({ id: 'queues.table.column.queueId' }),
            dataIndex: 'queueId',
            sorter: (a, b) => sorter(a, b, 'queueId'),
            ...getSearchFilterProps(intl, type, 'queueId')
          },
          {
            key: 'queueName',
            title: intl.formatMessage({ id: 'queues.table.column.queueName' }),
            dataIndex: 'queueName',
            sorter: (a, b) => sorter(a, b, 'queueName'),
            ...getSearchFilterProps(intl, type, 'queueName')
          },
          {
            key: 'storeId',
            title: intl.formatMessage({ id: 'queues.table.column.storeId' }),
            dataIndex: 'storeId',
            sorter: (a, b) => sorter(a, b, 'storeId'),
            ...getSearchFilterProps(intl, type, 'storeId')
          },
          {
            key: 'enabled',
            title: intl.formatMessage({ id: 'queues.table.column.enabled' }),
            dataIndex: 'enabled',
            render: enabled =>
              enabled ? (
                <CheckCircleFilled
                  style={{ fontSize: 20, color: colors.success }}
                />
              ) : (
                <CloseCircleFilled
                  style={{ fontSize: 20, color: colors.error }}
                />
              ),
            sorter: (a, b) => sorter(a, b, 'enabled')
          },
          actionsRow(['queueId', 'storeId'])
        ]
      }
    case TYPE_TILLS:
      return {
        title: 'tills.button.new',
        rowKey: row => row.tillId,
        columns: [
          {
            key: 'till',
            title: intl.formatMessage({ id: 'tills.table.column.till' }),
            dataIndex: 'till',
            sorter: (a, b) => sorter(a, b, 'till'),
            ...getSearchFilterProps(intl, type, 'till')
          },
          {
            key: 'tillId',
            title: intl.formatMessage({ id: 'tills.table.column.tillId' }),
            dataIndex: 'tillId',
            sorter: (a, b) => sorter(a, b, 'tillId'),
            ...getSearchFilterProps(intl, type, 'tillId')
          },
          {
            key: 'queueName',
            title: intl.formatMessage({ id: 'tills.table.column.queueName' }),
            dataIndex: 'queueName',
            sorter: (a, b) => sorter(a, b, 'queueName'),
            ...getSearchFilterProps(intl, type, 'queueName')
          },
          actionsRow(['tillId'])
        ]
      }
    case TYPE_DISPLAYS:
      return {
        title: 'displays.button.new',
        rowKey: row => row.displayId,
        columns: [
          {
            key: 'displayId',
            title: intl.formatMessage({
              id: 'displays.table.column.displayId'
            }),
            dataIndex: 'displayId',
            sorter: (a, b) => sorter(a, b, 'displayId'),
            ...getSearchFilterProps(intl, type, 'displayId')
          },
          {
            key: 'queueName',
            title: intl.formatMessage({
              id: 'displays.table.column.queueName'
            }),
            dataIndex: 'queueName',
            sorter: (a, b) => sorter(a, b, 'queueName'),
            ...getSearchFilterProps(intl, type, 'queueName')
          },
          {
            key: 'timer',
            title: intl.formatMessage({ id: 'displays.table.column.timer' }),
            dataIndex: 'timer',
            sorter: (a, b) => sorter(a, b, 'timer'),
            ...getSearchFilterProps(intl, type, 'timer')
          },
          actionsRow(['displayId'])
        ]
      }
    default:
      return {}
  }
}

const Table = props => {
  const { intl, isLoading, data, type, openEditModal, deleteRow } = props

  const tableConfig = getTableConfig(intl, type, openEditModal, deleteRow)
  const rowKey = useCallback(row => tableConfig.rowKey(row), [tableConfig])
  // (i) useCallback evita di ricostruire la funzione ad ogni render

  return (
    <AntTable
      size={'middle'}
      title={() => (
        <UppercaseButton
          icon={<PlusOutlined />}
          type={'primary'}
          onClick={() => openEditModal({})}>
          <FormattedMessage id={tableConfig.title} />
        </UppercaseButton>
      )}
      dataSource={data}
      columns={tableConfig.columns}
      rowKey={rowKey}
      loading={{ spinning: isLoading, indicator: <Loader /> }}
    />
  )
}
Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  deleteRow: PropTypes.func,
  isLoading: PropTypes.bool,
  openEditModal: PropTypes.func,
  type: PropTypes.string
}
export default injectIntl(Table)
