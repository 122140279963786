import {
  DELETE_DISPLAY,
  DELETE_QUEUE,
  DELETE_TILL,
  GET_DISPLAYS,
  GET_QUEUES,
  GET_TILLS,
  UPSERT_DISPLAY,
  UPSERT_QUEUE,
  UPSERT_TILL
} from '../action-types'
import api from 'src/common/api'
import { handleServerError } from 'src/common/utils'

export const getQueues = () => async dispatch => {
  try {
    const res = await api.queue.list()
    return dispatch({ type: GET_QUEUES, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}

export const upsertQueue = body => async dispatch => {
  try {
    await api.queue.upsert(body)
    return dispatch({ type: UPSERT_QUEUE })
  } catch (error) {
    return handleServerError(error)
  }
}

export const deleteQueue = (queueId, storeId) => async dispatch => {
  try {
    await api.queue.delete(queueId, storeId)
    return dispatch({ type: DELETE_QUEUE })
  } catch (error) {
    return handleServerError(error)
  }
}

export const getTills = () => async dispatch => {
  try {
    const res = await api.till.list()
    return dispatch({ type: GET_TILLS, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}

export const upsertTill = formBody => async dispatch => {
  const { till, tillId, queue } = formBody
  const body = {
    till,
    tillId,
    queueId: queue.split('_')[0],
    storeId: queue.split('_')[1]
  }
  try {
    await api.till.upsert(body)
    return dispatch({ type: UPSERT_TILL })
  } catch (error) {
    return handleServerError(error)
  }
}

export const deleteTill = tillId => async dispatch => {
  try {
    await api.till.delete(tillId)
    return dispatch({ type: DELETE_TILL })
  } catch (error) {
    return handleServerError(error)
  }
}

export const getDisplays = () => async dispatch => {
  try {
    const res = await api.display.list()
    return dispatch({ type: GET_DISPLAYS, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}

export const upsertDisplay = formBody => async dispatch => {
  const { displayId, timer, queue } = formBody
  const body = {
    displayId,
    timer,
    queueId: queue.split('_')[0],
    storeId: queue.split('_')[1]
  }
  try {
    await api.display.upsert(body)
    return dispatch({ type: UPSERT_DISPLAY })
  } catch (error) {
    return handleServerError(error)
  }
}

export const deleteDisplay = displayId => async dispatch => {
  try {
    await api.display.delete(displayId)
    return dispatch({ type: DELETE_DISPLAY })
  } catch (error) {
    return handleServerError(error)
  }
}
