import { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import {
  getQueues,
  getTills,
  upsertTill,
  deleteTill
} from '../../redux-store/actions/app-actions'

import Table from 'src/components/Table'
import ModalForm from 'src/components/ModalForm'
import { PageTitle } from 'src/common/styled'

import { TYPE_TILLS } from 'src/common/constants'

class Tills extends Component {
  static propTypes = {
    deleteTill: PropTypes.func,
    getQueues: PropTypes.func,
    getTills: PropTypes.func,
    queues: PropTypes.arrayOf(PropTypes.object),
    tills: PropTypes.arrayOf(PropTypes.object),
    upsertTill: PropTypes.func
  }
  state = {
    isLoading: false,
    isModalVisible: false,
    selectedRow: {}
  }

  componentDidMount() {
    this.getTills()
  }

  getQueues = async () => {
    const { getQueues } = this.props
    this.setState({ isLoading: true })
    await getQueues()
    this.setState({ isLoading: false })
  }

  getTills = async () => {
    const { getTills } = this.props
    this.setState({ isLoading: true })
    await getTills()
    this.setState({ isLoading: false })
  }

  upsertTill = async body => {
    const { upsertTill } = this.props
    this.setState({ isLoading: true })
    await upsertTill(body)
    this.getTills()
    this.closeEditModal()
  }

  deleteTill = async tillId => {
    const { deleteTill } = this.props
    this.setState({ isLoading: true })
    await deleteTill(tillId)
    this.getTills()
  }

  openEditModal = row => {
    this.getQueues()
    this.setState({ isModalVisible: true, selectedRow: row })
  }
  closeEditModal = () => {
    this.setState({ isModalVisible: false, selectedRow: {} })
  }

  render() {
    const { isLoading, isModalVisible, selectedRow } = this.state
    const { queues, tills } = this.props
    return (
      <>
        <PageTitle>
          <FormattedMessage id={'tills.title'} />
        </PageTitle>
        <Table
          isLoading={isLoading}
          data={tills}
          type={TYPE_TILLS}
          openEditModal={this.openEditModal}
          deleteRow={this.deleteTill}
        />
        <ModalForm
          isLoading={isLoading}
          isVisible={isModalVisible}
          type={TYPE_TILLS}
          row={selectedRow}
          queues={queues}
          closeModal={this.closeEditModal}
          submitForm={this.upsertTill}
        />
      </>
    )
  }
}
const mapStateToProps = state => ({
  queues: state.app.queues,
  tills: state.app.tills
})
const mapDispatchToProps = {
  getQueues,
  getTills,
  upsertTill,
  deleteTill
}
export default connect(mapStateToProps, mapDispatchToProps)(Tills)
