import { GET_DISPLAYS, GET_QUEUES, GET_TILLS } from '../action-types'

const initialState = {
  queues: [],
  tills: [],
  displays: []
}
const appReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_QUEUES:
      return { ...state, queues: payload }
    case GET_TILLS:
      return { ...state, tills: payload }
    case GET_DISPLAYS:
      return { ...state, displays: payload }
    default:
      return state
  }
}
export default appReducer
